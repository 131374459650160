import { FC } from 'react'
import { WithChildrenProps } from '@app/types/generalTypes'
import * as S from './MainHeader.styles'

interface MainHeaderProps extends WithChildrenProps {
  isTwoColumnsLayout: boolean
}

export const MainHeader: FC<MainHeaderProps> = ({ isTwoColumnsLayout, children }) => {
  return <S.Header $isTwoColumnsLayoutHeader={isTwoColumnsLayout}>{children}</S.Header>
}
