import { CollapseProps, Collapse as AntdCollapse } from 'antd'
import { FC } from 'react'

export type BaseCollapseProps = CollapseProps

interface BaseCollapseInterface extends FC<BaseCollapseProps> {
  Panel: typeof AntdCollapse.Panel
}

export const BaseCollapse: BaseCollapseInterface = props => {
  return <AntdCollapse {...props} />
}

BaseCollapse.Panel = AntdCollapse.Panel
