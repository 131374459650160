import React from 'react'
import {
  HomeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  FlagOutlined,
} from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'breadcrumbs.home',
    key: 'home',
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'common.create-event',
    key: 'create-event',
    url: '/create/event',
    icon: <PlusCircleOutlined />,
  },
  {
    title: 'common.event-list',
    key: 'event-list',
    icon: <UnorderedListOutlined />,
    children: [
      {
        title: 'common.congress',
        key: 'congress',
        url: '/congress',
      },
      {
        title: 'common.patronage',
        key: 'patronage',
        url: '/patronage',
      },
      {
        title: 'common.personal-contract',
        key: 'personal-contract',
        url: '/personal-contract',
      },
    ],
  },
  {
    title: 'common.kanban-board',
    key: 'kanban-board',
    url: '/kanban/board',
    icon: <AppstoreOutlined />,
  },
  {
    title: 'common.statuses',
    key: 'status-board',
    url: '/status/board',
    icon: <FlagOutlined />,
  },
]
