import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import { UserModel } from '@app/domain/UserModel'
import { persistUser, readUser } from '@app/services/localStorage.service'

export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: readUser(),
}

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', newUser => {
  newUser = {
    ...newUser,
    firstName: newUser.first_name,
    lastName: newUser.last_name,
    imgUrl: newUser.img_url,
    userName: newUser.username,
  }

  delete newUser['first_name']
  delete newUser['last_name']
  delete newUser['img_url']
  delete newUser['username']

  persistUser(newUser)

  return {
    payload: newUser,
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload
    })
  },
})

export default userSlice.reducer
