import { FC } from 'react'
import { DropdownCollapse } from '@app/components/header/Header.styles'
import { useTranslation } from 'react-i18next'
import { LanguagePicker } from '../LanguagePicker/LanguagePicker'
import { NightModeSettings } from '../nightModeSettings/NightModeSettings'
import { ThemePicker } from '../ThemePicker/ThemePicker'
import { BaseButton } from '@app/components/common/BaseButton/BaseButton'
import { useAppSelector } from '@app/hooks/reduxHooks'
import * as S from './SettingsOverlay.styles'

export const SettingsOverlay: FC = ({ ...props }) => {
  const { t } = useTranslation()

  const { isPWASupported, event } = useAppSelector(state => state.pwa)

  return (
    <S.SettingsOverlayMenu {...props}>
      <DropdownCollapse bordered={false}
        expandIconPosition='end'
        ghost
        defaultActiveKey='themePicker'
      >
        <DropdownCollapse.Panel header={t('header.changeLanguage')} key='languagePicker'>
          <LanguagePicker />
        </DropdownCollapse.Panel>
        <DropdownCollapse.Panel header={t('header.changeTheme')} key='themePicker'>
          <ThemePicker />
        </DropdownCollapse.Panel>
        <DropdownCollapse.Panel header={t('header.nightMode.title')} key='nightMode'>
          <NightModeSettings />
        </DropdownCollapse.Panel>
      </DropdownCollapse>
      {isPWASupported && (
        <S.PwaInstallWrapper>
          {/* eslint-disable-next-line no-undef */}
          <BaseButton block type='primary' onClick={() => event && (event as BeforeInstallPromptEvent).prompt()}>
            {t('common.pwa')}
          </BaseButton>
        </S.PwaInstallWrapper>
      )}
    </S.SettingsOverlayMenu>
  )
}
