import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { config, ConfigData } from '@app/api/config.api'
import { persistConfig, readConfig } from '@app/services/localStorage.service'

const initialState: {config: ConfigData[]} = {
  config: readConfig(),
}

export const loadConfig = createAsyncThunk('auth/loadConfig', async () =>
  config()
    .then((res: ConfigData[]) => {
      persistConfig(res)
      return res
    })
)

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      state.config = action.payload
    })
  },
})

export default configSlice.reducer
