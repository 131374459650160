import { FC } from 'react'
import { ConfigProvider } from 'antd'
import { HelmetProvider } from 'react-helmet-async'
import esES from 'antd/lib/locale/es_ES'
import enUS from 'antd/lib/locale/en_US'
import GlobalStyle from './styles/GlobalStyle'
import 'typeface-montserrat'
import 'typeface-lato'
import { AppRouter } from './components/router/AppRouter'
import { useLanguage } from './hooks/useLanguage'
import { useAutoNightMode } from './hooks/useAutoNightMode'
// import { usePWA } from './hooks/usePWA'
import { useThemeWatcher } from './hooks/useThemeWatcher'
import { useAppSelector } from './hooks/reduxHooks'
import { themeObject } from './styles/themes/themeVariables'

const App: FC = () => {
  const { language } = useLanguage()
  const theme = useAppSelector(state => state.theme.theme)

  // usePWA()

  useAutoNightMode()

  useThemeWatcher()

  return (
    <>
      <meta name='theme-color' content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : esES}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  )
}

export default App
