import { UserModel } from '@app/domain/UserModel'

interface ConfigData {
    id: number;
    name: string;
    description: string;
}

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token)
}

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || ''
}

export const readConfig = (): ConfigData[] => {
  const configStr = localStorage.getItem('config')
  return configStr ? JSON.parse(configStr) : []
}

export const persistConfig = (config: ConfigData[]): void => {
  localStorage.setItem('config', JSON.stringify(config))
}

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user')
  return userStr ? JSON.parse(userStr) : null
}

export const deleteToken = (): void => localStorage.removeItem('accessToken')
export const deleteUser = (): void => localStorage.removeItem('user')
