import { httpApi } from '@app/api/http.api'

export interface ConfigData {
    id: number;
    name: string;
    description: string;
}

export const config = (): Promise<ConfigData[]> =>
  httpApi.get<ConfigData[]>('api/v1/event-types/')
    .then(data => data.data)
