import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout'
import { LAYOUT } from '@app/styles/themes/constants'
import { media } from '@app/styles/themes/constants'
import styled, { css } from 'styled-components'

interface IHeader {
  $isTwoColumnsLayoutHeader: boolean
}

export const Header = styled(BaseLayout.Header)<IHeader>`
  line-height: 1.5;

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
  }

  @media only screen and ${media.md} {
    ${props =>
    props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`
