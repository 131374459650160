import { Avatar, AvatarProps } from 'antd'
import { FC } from 'react'

export type BaseAvatarProps = AvatarProps

interface BaseAvatarInterface extends FC<BaseAvatarProps> {
  Group: typeof Avatar.Group
}

export const BaseAvatar: BaseAvatarInterface = props => {
  return <Avatar {...props} />
}

BaseAvatar.Group = Avatar.Group
