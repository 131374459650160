import React, { useState } from 'react'
import { /* Link, */ useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm'
import { useAppDispatch } from '@app/hooks/reduxHooks'
import { doLogin } from '@app/store/slices/authSlice'
import { loadConfig } from '@app/store/slices/configSlice'
import { notificationController } from '@app/controllers/notificationController'
import * as S from './LoginForm.styles'
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles'
import loginBG from '@app/assets/images/login-header.png'
import { BaseImage } from '@app/components/common/BaseImage/BaseImage'

interface LoginFormData {
  email: string
  password: string
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true)
    await dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        dispatch(loadConfig())
          .unwrap()
          // .then(r => console.log('r:', r))
          .catch(err => {
            notificationController.error({ message: err.message })
          })
        navigate('/')
      })
      .catch(err => {
        notificationController.error({ message: err.message })
        setLoading(false)
      })
  }

  return (
    <Auth.FormWrapper>
      <BaseImage src={loginBG} alt='article' preview={false} />
      <BaseForm layout='vertical'
        onFinish={handleSubmit}
        requiredMark='optional'
        initialValues={initValues}
      >

        {/* <Auth.FormTitle>{t('common.login')}</Auth.FormTitle> */}
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name='email'
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name='password'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        {/* <Auth.ActionsWrapper> */}
        {/* <BaseForm.Item name='rememberMe' valuePropName='checked' noStyle> */}
        {/*   <Auth.FormCheckbox> */}
        {/*     <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText> */}
        {/*   </Auth.FormCheckbox> */}
        {/* </BaseForm.Item> */}
        {/* <Link to='/auth/forgot-password'> */}
        {/*   <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText> */}
        {/* </Link> */}
        {/* </Auth.ActionsWrapper> */}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  )
}
