import React from 'react'
import * as S from './References.styles'
import { LinkedinOutlined } from '@ant-design/icons'

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        Made by{' '}
        <a href='https://www.mambo.com.py' target='_blank' rel='noreferrer'>
          Mambo Business Technology{' '}
        </a>
        in 2024 &copy;.
      </S.Text>
      <S.Icons>
        <a href='https://www.linkedin.com/company/mambo-s-a-/about/' target='_blank' rel='noreferrer'>
          <LinkedinOutlined />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  )
}
