import { FC, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// no lazy loading for auth pages to avoid flickering
const AuthLayout = lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'))
import LoginPage from '@app/pages/LoginPage'

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout'
import RequireAuth from '@app/components/router/RequireAuth'
import { withLoading } from '@app/hocs/withLoading.hoc'

const DataTablesPage = lazy(() => import('@app/pages/DataTablesPage'))
const ServerErrorPage = lazy(() => import('@app/pages/ServerErrorPage'))
const Error404Page = lazy(() => import('@app/pages/Error404Page'))
const Logout = lazy(() => import('./Logout'))
const CreateEventPage = lazy(() => import('@app/pages/events/CreateEventPage'))
const HomePage = lazy(() => import('@app/pages/events/HomePage'))
const CongressPage = lazy(() => import('@app/pages/events/CongressPage'))
const EventBudgetPage = lazy(() => import('@app/pages/events/EventBudgetPage'))
const KanbanPage = lazy(() => import('@app/pages/Kanban/KanbanPage'))
const StatusBoardPage = lazy(() => import('@app/pages/Status/StatusBoardPage'))

export const HOME_DASHBOARD_PATH = '/'

const DataTables = withLoading(DataTablesPage)

const ServerError = withLoading(ServerErrorPage)
const Error404 = withLoading(Error404Page)

// Events
const Home = withLoading(HomePage)
const CreateEvent = withLoading(CreateEventPage)
const Congress = withLoading(CongressPage)
const EventBudget = withLoading(EventBudgetPage)
const Kanban = withLoading(KanbanPage)
const StatusBoard = withLoading(StatusBoardPage)

const AuthLayoutFallback = withLoading(AuthLayout)
const LogoutFallback = withLoading(Logout)

export const AppRouter: FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Home />} />

          <Route path='create'>
            <Route path='event' element={<CreateEvent />} />
          </Route>

          <Route path='event'>
            <Route path='budget' element={<EventBudget />} />
          </Route>

          <Route path='kanban'>
            <Route path='board' element={<Kanban />} />
          </Route>

          <Route path='status'>
            <Route path='board' element={<StatusBoard />} />
          </Route>

          <Route path='congress' element={<Congress />} />
          <Route path='data-tables' element={<DataTables />} />
          <Route path='server-error' element={<ServerError />} />
          <Route path='404' element={<Error404 />} />
        </Route>

        <Route path='/auth' element={<AuthLayoutFallback />}>
          <Route path='login' element={<LoginPage />} />
        </Route>
        <Route path='/logout' element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  )
}
